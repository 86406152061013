import { request } from './utils';
import { formatDate, serverSidePaginationSearchParams } from './../utils/formatters';
import download from "downloadjs";

export default class DealerService {
    static async getAll() {
        const response = await request('/dealers');
        return response.data;
    }

    static async getAllWithAdditionalMetrics() {
      const response = await request('/dealers/all/extra');
      return response.data;
  }

    static async get(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/`);
      return response.data;
    }

    static async getDelinquencies(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/delinquencies`);
      return response.data;
    }

    static async getActiveLoans(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/active-loans`);
      return response.data;
    }

    static async getOutstandingFunds(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/outstanding-funds`);
      return response.data;
    }

    static async getDealerPeopleFundedMetrics(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/people-funded-metrics`);
      return response.data;
    }

    static async getDealerFundingYTD(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/funding-ytd`);
      return response.data[0];
    }

    static async getDealerPromoCount(dealerNumber) {
      const response = await request(`dealers/${dealerNumber}/promo-count`);
      return response.data;
    }

    static async getAllUnique(limit, offset) {
      const response = await request(`/dealers-unique${serverSidePaginationSearchParams(limit, offset)}`);
      return response.data;
    }

    static async getAllOrgs() {
      const response = await request('/organizations');
      return response.data;
    }

    static async newDealerComment(dealerNumber, comment, commentCategory) {
      const response = await request(`/dealers/${dealerNumber}/comment`, {
          method: 'POST',
          data: JSON.stringify({"comment": comment, "commentCategory": commentCategory})
      });

      return response;
  }

  static async deleteDealerComment(id) {
      const response = await request(`/dealers/comment/delete`, {
          method: 'PATCH',
          data: JSON.stringify({commentId: id})
      });

      return response;
  }
  static async deleteTestTransaction(id) {
    const response = await request(`/dealers/test-transaction/delete`, {
          method:'POST',
          data: JSON.stringify({id: id})
    });
    return response;
  }

  static async getDealerCommentsForDealer(dealerNumber) {
      const response = await request(`/dealers/${dealerNumber}/comment`);

      return response.data;
  }

  static async getDealerPopupsForDealer(dealerNumber) {
    const response = await request(`/dealers/${dealerNumber}/popup-comment`);

    return response.data;
}

    static async bulkUpload(files) {
        const data = new FormData();
      
        for (const file of files) {
          data.append('files[]', new Blob([file.data]), file.name);
        }

        const response = await request('/dealers/upload', {
          method: 'POST',
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }      
        });

        return response.data;
    }

    static async newDealer(dealer) {
      const response = await request('/dealers/new', {
        method: 'POST',
        data: JSON.stringify(dealer)
      });

      return response.data;
    }

    static async updateDealer(dealer) {
      const response = await request('/dealers/update', {
        method: 'POST',
        data: JSON.stringify(dealer)
      });

      return response;
    }
    static async syncDealerWithHubspot(DealerID)
    {
        const response = await request('/tools/sync-dealer-with-hubspot', {
            method: 'POST',
            data: [parseInt(DealerID)],
        });
        // console.log(response)
        return response;
    }
    static async updateDealerManualFlag(newValue, dealerNumber, flagReason) {
      const response = await request(`/dealers/${dealerNumber}/flag`, {
        method: 'PATCH',
        data: JSON.stringify({newValue: newValue, flagReason: flagReason})
      });

      return response;
    }

    static async updateDealerHasFincenIDFlag(newValue, dealerNumber) {
      const response = await request(`/dealers/${dealerNumber}/has-fincen-id`, {
        method: 'PATCH',
        data: JSON.stringify({hasFincenId: newValue})
      });

      return response;
    }

    static async getDealerHistory(dealerNumber) {
        const response = await request(`/dealers/${dealerNumber}/history`);

        return response.data;
    }
    static async refreshDealerMetric()
    {
      const response = await request('tools/refresh-dealer-view');
      return response;
    }
    static async getAllTestTransactions() {
      const response = await request('/test-transactions');
      return response.data;
  }
    static async resetTestTransactionCurrentDate()
    {
      const formattedDate = formatDate(new Date());
      const response = await request(`/tools/reset-test-transaction/${encodeURIComponent(formattedDate)}`)

      return response.status;
    }
    static async getDealerTestTransactions(dealerNumber) {
      const response = await request(`/test-transactions/dealer/${dealerNumber}`);
      return response.data;
  }
    static async newTestTransaction(dealerNumber, amount) {
      const response = await request('/test-transactions/new', {
        method: 'POST',
        data: JSON.stringify({dealerNumber: dealerNumber, amount: amount})
      })
      return response;
  }
    static async updateTestTransaction(trxnId, status) {
      const response = await request('/test-transactions/update', {
        method: 'POST',
        data: JSON.stringify({trxnId: trxnId, status: status})
      })
      return response;
  }

  static async updateDealerTestTransaction(dealerNumber, status) {
    const response = await request('/test-transactions/dealer-update', {
      method: 'POST',
      data: JSON.stringify({dealerNumber: dealerNumber, status: status})
    })
    return response;
}

  static async verifyBulkTestTransactions(trxnIds) {
    const response = await request('/test-transactions/update/bulk', {
      method: 'POST',
      data: JSON.stringify({trxnIds: trxnIds})
    })
    return response;
  }

  static async verifyBulkDealers(dealerIds) {
    const response = await request('/test-transactions/update/dealer-bulk', {
      method: 'POST',
      data: JSON.stringify({dealerIds: dealerIds})
    })
    return response;
  }

  static async downloadNachaTrxnFile() {
    const response = await request(`/loans/daily-processing/nacha-test-trxns/download`);
    if (response.status === 200) {
        download(response.data, response.headers["x-filename"]);
        return response.data;
    } else if (response.status === 204) {
        return response.data;
    } else {
        throw new Error("Server error downloading Nacha TT File");
    }
}
}