import { createTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#f89d3c',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#66bb6a',
    contrastText: '#ffffff'
  },
  warning: {
    main: '#ff0000',
    contrastText: '#ffffff'
  },
  grey: {
    main: '#E0E0E0',
    contrastText: '#000000'
  }
};

const overrides = {
  MuiTabs: {
    root: {
      display: 'inline-flex'
    },
    indicator: {
      backgroundColor: '#ffffff'
    }
  },
  MuiTab: {
    wrapper: {
      paddingTop: 2
    }
  }
};

export default createTheme({
  palette,
  overrides
});