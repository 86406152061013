import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoanService } from '../services';
import { timeAgo } from '../utils/formatters';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import PhoneIcon from '@material-ui/icons/Phone';
import blue from '@material-ui/core/colors/blue';
import TimelineDot from '@material-ui/lab/TimelineDot';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import NoteIcon from '@material-ui/icons/Note';
const styles = makeStyles((theme) => ({
    root: {},
    summaryHeader: {
        textAlign: 'center',
        textDecoration: 'underline',
    },
    secondRow: {
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
        margin: "25px",
        padding: "10px",
        width: "40vw",
    },
    primaryContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    notesWrapper: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    commentBody: {
        margin: '10px 10px 10px 5px',
        background: '#f1f1f1',
        padding: '10px',
        borderRadius: '2%',
        display: 'inline-block',
        textAlign: 'center',
        width: '90%'
    },
    commentDate: {
        fontSize: '12px',
        display: 'flex'
    },
    commentDivider: {
        paddingLeft: '10px',
        paddingRight: '10px',
        alignItems: 'center',
        display: 'flex'
    },
}));

function determineIcon(comment) {
    let phoneRegex = /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/g;
    let chatRegex = /-------------------- Chat Log/g;
    let paymentRegex = /https:\/\//g;
    let icon = <TimelineDot style={{backgroundColor: grey[400], width: '36px', height: '36px' }} ><NoteIcon /></TimelineDot>;

    if (comment?.match(chatRegex)) {
        icon = <TimelineDot style={{backgroundColor: green[300], width: '36px', height: '36px' }} ><ChatBubbleIcon /></TimelineDot>;
    } else if(comment?.match(phoneRegex)) {
        icon = <TimelineDot style={{backgroundColor: blue[300], width: '36px', height: '36px' }} ><PhoneIcon /></TimelineDot>;
    } else if(comment?.match(paymentRegex)) {
        icon = <TimelineDot style={{backgroundColor: green[500], width: '36px', height: '36px' }} ><AttachMoneyIcon/></TimelineDot>;
    }

    return icon;
}

const NLSComments = (props) => {
    const classes = styles();
    const { loanNumber } = props;
    const [nlsComments, setNlsComments] = useState(null);

    useEffect(() => {
        LoanService.getNLSCommentsForLoan(loanNumber).then(response => {
            if (response.status && response.status === 200) {
                setNlsComments(response.data?.nlsComments);
            } else {
                console.log(`Failed to retrieve NLS Comments, please contact support`);
            }
        });
    }, [loanNumber]);
    return (
        <Grid item className={classes.secondRow}>
            <h3 className={classes.summaryHeader}>NLS Comments</h3>
            <div className={classes.notesWrapper}>
                <List>
                {
                    nlsComments ? nlsComments.map(currentComment => {
                        return (
                            <Fragment key={currentComment.commentId}>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={
                                            <Fragment>
                                                <div className={classes.primaryContainer}>
                                                    <Typography>
                                                        {determineIcon(currentComment.comment)}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        className={`${classes.inline} ${classes.commentDivider}`}
                                                    >
                                                        {currentComment.portfolio}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        className={`${classes.inline} ${classes.commentDate}`}
                                                    >
                                                        {`(${timeAgo(currentComment.commentDate)})`}
                                                    </Typography>
                                                </div>
                                            </Fragment>
                                        }
                                        secondary={
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                className={classes.commentBody}
                                                color="textPrimary"
                                            >
                                                {currentComment.comment || 'Comment Unavailable'}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </Fragment>
                        );
                    }) : null
                }
                </List>
            </div>
        </Grid>
    )
}

export default NLSComments;
