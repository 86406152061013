import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DealerDiff } from '../../components';
import { DealerService } from '../../services';
import { formatDateTime } from '../../utils/formatters';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InfoIcon from '@material-ui/icons/Info';
import orange from '@material-ui/core/colors/orange';
import ListItemText from '@material-ui/core/ListItemText';
import { useNotification } from '../../contexts/NotificationContext';


const styles = makeStyles((theme) => ({
    dealerHistory: {
        maxHeight: '60vh',
        overflowY: 'scroll'
    },
    commentDivider: {
        paddingLeft: '5px',
        paddingRight: '10px'
    },
    commentDate: {
        fontSize: '12px',
    },
    cutoverWarning: {
        backgroundColor: 'lightgoldenrodyellow',
        borderRadius: '10px',
        padding: '10px',
        marginTop: '10px',
    }
}));

const DealerAuditLog = ({ dealerNumber }) => {
    const classes = styles();
    const [dealerHistory, setDealerHistory] = useState(null);
    const { createErrorNotification } = useNotification();

    useEffect(() => {
        DealerService.getDealerHistory(dealerNumber).then(data => {
            setDealerHistory(data.dealerHistory);
        }).catch(err => {
            console.error(`Failed to load dealer audit history for dealer: ${dealerNumber}`, err);
            createErrorNotification(`Failed to load dealer audit history for dealer: ${dealerNumber}`);
        });
    }, [dealerNumber, createErrorNotification]);

    const historyDiff = (oldData, newData) => {
        if (!newData) return true;

        const invalidKeys = ['changeType', 'change', 'modificationDate', 'modifiedBy'];
        for (const [key, value] of Object.entries(oldData.dealer)) {
            if (invalidKeys.includes(key)) {
                return;
            } else {
                if (value !== newData.dealer[key]) {
                    return true;
                }
            }
        }
        return false;
      }
    
     return dealerHistory === null ?
        (<h1>Loading...</h1>) :
        <div className={classes.dealerHistory}>
            <List>
                {
                    dealerHistory.map((dealerHistoryItem, index) => {
                        const oldValue = dealerHistory[index - 1];
                        const hasDiff = historyDiff(dealerHistoryItem, oldValue);

                        return dealerHistoryItem.changeType === 'LATEST' || !hasDiff ? null : (
                             <>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={dealerHistoryItem.modifiedBy} src="/does/not/exist/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Fragment>
                                                <Typography
                                                    component="span"
                                                    className={`${classes.inline}`}
                                                >
                                                    {dealerHistoryItem?.modifiedBy} -
                                                </Typography> 
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={`${classes.inline} ${classes.commentDivider}`}
                                                >
                                                    {dealerHistoryItem.changeType}
                                                </Typography> 
                                                <Typography
                                                    component="span"
                                                    className={`${classes.inline} ${classes.modificationDate}`}
                                                >
                                                {`(${formatDateTime(dealerHistoryItem.modificationDate)})`}
                                                </Typography>
                                            </Fragment>
                                        }
                                        secondary={
                                            <>
                                                { dealerHistoryItem.changeType === 'CUTOVER' ? 
                                                    <div className={classes.cutoverWarning}>
                                                        <Typography
                                                            component="span"
                                                            variant="body1"
                                                            color="textPrimary"
                                                        >
                                                                <InfoIcon style={{ color: orange[700], marginRight: '5px' }} />
                                                                {dealerHistoryItem.change}
                                                        </Typography>
                                                    </div>
                                                : null }
                                            </>
                                        }
                                    />
                                </ListItem>
                                <DealerDiff newValue={dealerHistoryItem?.dealer} oldValue={oldValue?.dealer} changeType={dealerHistoryItem.changeType} />
                            </>
                        );
                    }).reverse()
                }
            </List>
        </div>
}

export default DealerAuditLog;