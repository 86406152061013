/* eslint eqeqeq: "off" */
import React from 'react';
import ReactJsonViewCompare from 'react-json-view-compare';

const DealerDiff = ({newValue, oldValue, changeType}) => {
    let newData = {};
    let oldData = {};

    if (['CUTOVER', 'CREATED'].includes(changeType)) {
        newData = newValue;
    } else {
        for (const [key, value] of Object.entries(oldValue || {})) {
            if (value != newValue[key]) {
                // remove the unchanged values
                newData[key] = newValue[key];
                oldData[key] = oldValue[key];
            }
        }
    }

    return (
        <div>
            <ReactJsonViewCompare oldData={oldData} newData={newData} />
        </div>
    );
}

export default DealerDiff;
