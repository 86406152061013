import axios from 'axios';

/** @important we could honestly just move ALL the endpoints here, there aren't that many. Just search all the instances of request across the repo */
const request = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

const p4r_request = axios.create({
    baseURL: process.env.REACT_APP_P4_BACKEND_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

/** @see https://axios-http.com/docs/interceptors */
request.interceptors.response.use(succesfulResponse => succesfulResponse, function (error) {

    /**
     * @important this is JUST A HOTFIX. We should be using the history from createBrowserHistory, but we don't have access to it here due to it not being a component.
     * In reality, we should rewrite the entire fetch system elsewhere (maybe define it in a context provider in the App.js file, and pass down the request function to all children via props)
     * Additionally, we need to do some proper error handling for 404s, 500s, etc
     * That, however, goes WAY beyond the scope (and timeframe) of my changes.
     */
    console.log('REDIRECTION SWITCH IS ACTIVATING:');
    console.log(error.response.status);
    switch (error.response.status){
        case 401:
            window.location = '/login';
            break;
        default:
            break;
    }

    return Promise.reject(error)
})

p4r_request.interceptors.response.use(succesfulResponse => succesfulResponse, function (error) {
    console.log('REDIRECTION SWITCH IS ACTIVATING:');
    console.log(error.response.status);
    switch (error.response.status){
        case 401:
            window.location = '/login';
            break;
        default:
            break;
    }

    return Promise.reject(error)
})

export {request, p4r_request}
